@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', 'Oswald', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  p {
    @apply font-body
  }
  a {
    @apply font-body
  }
  h1 {
    @apply font-display
  }
  h2 {
    @apply font-display
  }
}

.button-hero {
  @apply rounded-3xl bg-blue-600 px-10 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 
}

.button-navbar {
  @apply rounded-3xl bg-blue-600 px-5 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 
  
}

.section-title-pill {
  @apply rounded-3xl bg-slate-950 text-base font-semibold leading-7 text-white
}

.text-input {
  @apply block w-full rounded-md border-0 bg-black/5 px-3.5 py-2 text-black shadow-sm ring-1 ring-inset ring-black/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6
}

.label-input {
  @apply block text-sm font-semibold leading-6 text-black
}

.alt-section-title-pill {
  @apply rounded-3xl bg-slate-300 text-base font-semibold leading-7 text-black
}